/* Footer.css */
footer {
    background-color: rgb(0, 0, 0);
    position: fixed;
    bottom: 0;
    color: #ffffff;
    left: 0;
}

.brand-logo {
    text-align: left;
}

.footnote {
    text-align: right;
}

.text-sm {
    font-size: 14px;
}

@media screen and (max-width: 390px) {
    .container-footer {
        flex-direction: column;
        align-items: center;
    }

    .brand-logo {
        margin-bottom: 0px;
    }
}

@media screen and (max-width: 375px) {
    .container-footer {
        flex-direction: column;
        align-items: center;
    }

    .brand-logo {
        margin-bottom: 0px;
    }
}