.App {
  text-align: center;
}

body {
  padding: 10px;
}

/* Scrollbar modification */

::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: white;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: rgba(21, 197, 147, 0.8);

}


/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: rgba(3, 12, 74, 0.832);
}