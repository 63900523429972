.page-header {
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    background-color: rgba(5, 5, 5, 0.521);
    color: rgba(255, 255, 255, 0.95);
    display: flex;
    justify-content: space-between;
    padding: 0 1rem;
    height: 3rem;
    width: 100%;
    font-weight: 400;
    padding-top: 5px;
    justify-self: center;
    align-items: center;
    text-align: center;

}

.page-header .navbar-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 90rem;
    margin-top: 25px;
    margin-bottom: 25px;
    margin-left: auto;
    margin-right: auto;
}

.brand-logo {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: white;
    max-width: 70px;
    height: auto;
}

.menu {
    display: flex;
    flex-direction: row;
    gap: 30px;
}

.nav-link {
    text-decoration: none;
    color: white;
    font-size: 16px;
}

.nav-link.current {
    font-weight: bold;
}

.nav-links a:hover {
    color: rgb(55 48 163);
    transition: background-color 0.3s ease;
}

/* Media query for smaller screens */
@media screen and (max-width: 375px) {
    .menu {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        top: 2rem;
        /* Adjust as needed */
        left: 0;
        width: 100%;
        background-color: rgba(5, 5, 5, 0.8);
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        z-index: 1;
        transition: transform 0.3s ease-in-out;
        transform: translateY(-100%);
        /* Initially hidden above the screen */
    }

    .menu.open {
        transform: translateY(0);
        /* Display the menu */
    }

    .brand-logo {
        width: auto;
        height: 6px;
    }

    .nav-link {
        /* Add or override styles as needed for smaller screens */
        text-align: center;
        font-size: 12px;
    }

    .page-header {
        padding-left: 50px;
        padding-right: 50px;
    }
}

@media screen and (max-width: 390px) {
    .menu {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        top: 3rem;
        /* Adjust as needed */
        left: 0;
        width: 100%;
        background-color: rgba(5, 5, 5, 0.8);
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        z-index: 1;
        transition: transform 0.3s ease-in-out;
        transform: translateY(-100%);
        /* Initially hidden above the screen */
    }

    .menu.open {
        transform: translateY(0);
        /* Display the menu */
    }

    .nav-link {
        /* Add or override styles as needed for smaller screens */
        text-align: center;
        font-size: 12px;
    }

    .page-header .navbar-container {
        padding-left: 4rem;
        padding-right: 4rem;
    }

    .brand-logo {
        width: auto;
        height: 6px;
    }
}